@use '@angular/material' as mat;
.custom-dialog-container {
  mat-dialog-container {
    padding: 0;
  }
}

mat-dialog-container {
  & > * > .header {
    margin-top: -24px;
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 24px;
    width: calc(100% + 48px);
    background-color: mat.get-color-from-palette($primary);
    color: #000000;
    line-height: 48px;

    & > mat-icon {
      vertical-align: top;
      font-size: 20px;
      text-align: center;
      margin-top: 12px;
      line-height: 24px;
    }

    & > button.close {
      margin-top: 4px;
      opacity: 0.3;
      transition: all 0.3s linear;

      &:hover {
        opacity: 1;
      }
    }
  }

  & > * > .container {
    padding: 12px 0;
  }

  & > * > .footer {
    margin-bottom: -24px;
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 24px;
    line-height: 72px;
    background: #333333;
  }
}

.dialog-container-p0 mat-dialog-container {
  padding: 0px !important;

  & > * > .header {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.dialog-container-p12 mat-dialog-container {
  padding: 12px !important;

  & > * > .header {
    margin-top: -12px;
    margin-left: -12px;
    margin-right: -12px;
    padding: 0 12px;
  }
}

.dialog-container-p16 mat-dialog-container {
  padding: 16px !important;

  & > * > .header {
    margin-top: -16px;
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 16px;
  }
}

.dialog-store-preview mat-dialog-container {
  border-radius: 24px;
  padding: 0px !important;

  & > * > .header {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}
