mat-icon,
fa-icon {
  user-select: none;

  &.x24 {
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
  }
}

.mi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  user-select: none;
}
