.control-point {
  width: 12px;
  height: 12px;
  transform: translate(-6px, -6px);
  border: 1px solid rgba(255, 193, 9, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  position: absolute;
  z-index: 9 !important;
}

.control-point.rotate {
  width: 14px;
  height: 14px;
  transform: translate(-7px, -7px);
  border-radius: 100%;
}
