.context-menu {
  transform-origin: left top;
}

.tiny-menu {
  button,
  mat-tree-picker-node {
    line-height: 32px;
    font-size: 0.8em;
    height: 32px;
    min-height: 32px;
    vertical-align: top;
  }

  .mat-divider {
    margin: 8px 0;
  }

  mat-icon {
    vertical-align: top !important;
    height: 32px;
    width: 16px;
    font-size: 16px;
    line-height: 32px;
  }
}
