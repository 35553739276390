::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #FFC10752;
  cursor: pointer;
  &:hover {
    background: #FFC107B2;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
}
