.search {
  .wrap {
    background-color: rgba(255, 255, 255, 0.04);
    width: 100%;
    border-radius: 4px;
    padding: 4px;

    & > mat-icon {
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      user-select: none;
    }

    input {
      font-size: 18px;
      color: #aaaaaa;
      box-sizing: border-box;
      display: block;
      border: none;
      outline: none;
      background-color: transparent;
      width: 100%;
    }
  }
}
