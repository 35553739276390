mat-sidenav-container {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
}

mat-sidenav.mat-drawer {
  width: 260px;
  background-color: #303030;

  & > [fxLayout] {
    height: calc(100vh - 64px);
  }
}
